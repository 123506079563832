module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9b3c35f1ba25c09c3513219af7ec5087"},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"mtmContainerId":"container_X3XDs5Gr","includeInDevelopment":false,"mtmHost":"https://premium.analys.cloud","routeChangeEventName":false,"trackPageViews":true,"disableLinkTracking":true,"disableContentImpressionTracking":true,"mtmDefaultDataVariable":null,"mtmPAQDefaultDataVariable":null,"requireCookieConsent":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
